import React from "react"
import styled from "styled-components/macro"
import { breakpoint as bp, Header, HeaderTitle, HeaderSub } from "./styles"
import Image from "./Img"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Logo from "./logo"

function HeroComponent() {
  const query = useStaticQuery(graphql`
    query {
      indexImage: file(relativePath: { eq: "review-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Hero fluid={query.indexImage.childImageSharp.fluid}>
      <Inner>
        <Content>
          <HeroImages>
            <Stars>
              <Image alt="review stars" filename="stars.png" />
            </Stars>
            <Rocket>
              <Image alt="review rocket" filename="rocket.png" />
            </Rocket>
          </HeroImages>

          <Header className="heading-primary u-padding-bottom-0">
            <Logo />
            <HeaderTitle>REVIEWS</HeaderTitle>
            <HeaderSub>
              Over the years we’ve collected hundreds of positive reviews from
              people that we helped create retirement plans. Here are some of
              them.
            </HeaderSub>
          </Header>
        </Content>
      </Inner>
    </Hero>
  )
}

const Hero = styled(BackgroundImage)`
  position: relative;
  min-height: 792px;
  padding-top: 64px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  @media only screen and (max-width: 1024px) {
    margin-top: 0;
  }

  @media only screen and (min-width: 1280px) and (max-width: 1290px) {
    min-height: 275px;
  }

  @media only screen and (max-width: ${bp.largest}) {
    min-height: 650px;
  }

  @media only screen and (max-width: ${bp.medium}) {
    min-height: 650px;
  }
`

const Inner = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;

  @media only screen and (min-width: ${bp.medium}) {
    z-index: 4;
  }
`

const Content = styled.div`
  position: relative;
  display: flex;
  max-width: var(--page-container);
  margin-left: auto;
  margin-right: auto;
  padding: 0 1.5rem;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  @media only screen and (min-width: 1280px) and (max-width: 1290px) {
    max-width: 660px;
  }
`

const HeroImages = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;

  & > img {
    object-fit: contain;
  }
`

const Stars = styled.div`
  position: absolute;
  width: 892px;
  height: 550px;
  left: 50%;
  top: -35%;
  transform: translateX(-51.5%);
  z-index: 1;
  @media only screen and (max-width: ${bp.largest}) {
    width: 656px;
    height: 404px;
  }

  @media only screen and (max-width: ${bp.small}) {
    width: 320px;
    height: 222px;
  }
`

const Rocket = styled.div`
  position: relative;
  width: 164px;
  height: 458px;
  z-index: 2;

  @media only screen and (max-width: ${bp.largest}) {
    width: 116px;
    height: 326px;
  }

  @media only screen and (max-width: ${bp.large}) {
    width: 116px;
    height: 326px;
  }
`

export default HeroComponent
