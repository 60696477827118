import React from "react"
import styled from "styled-components/macro"

import { generateArray } from "../helpers"
import Star from "../icons/star-full.svg"

export default function starRating({ rating, isLeft }) {
  return (
    <Wrapper isLeft={isLeft}>
      {generateArray(rating).map(star => (
        <Star key={star} />
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  text-align: ${props => (props.isLeft ? "left" : "right")};
  svg {
    display: inline-block;
    width: 12px;
    height: 12px;
    margin: 0 2px;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`
