import React from "react"
import styled, { css } from "styled-components/macro"
import QuoteIcon from "../../icons/quote.svg"

export default function BlockqouteComponent({ children }) {
  return (
    <Blockquote>
      <Top>
        <QuoteIcon />
      </Top>
      {children}
      <Bottom>
        <QuoteIcon />
      </Bottom>
    </Blockquote>
  )
}

const Blockquote = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`

const Icon = css`
  position: relative;
  width: 71px;
  height: 48px;
`

const Top = styled.div`
  ${Icon}
  align-self: flex-start;
  transform: rotate(180deg);
`

const Bottom = styled.div`
  ${Icon}

  align-self: flex-end;
`
