export function generateArray(length, step, start = 1) {
  let array = new Array(length)
  for (var i = 0; i < length; i++, start++) {
    if (step) {
      array[i] = start * step
    } else {
      array[i] = start
    }
  }
  return array
}
