import React from "react"
import { Link } from "gatsby-plugin-modal-routing"
import styled from "styled-components/macro"
import StarRating from "../starRating"
import Blockquote from "./blockqoute"
import LinkIcon from "../../icons/external-link.svg"

function ReviewCard({
  headerImage,
  title,
  comment,
  id,
  avatarUrl,
  rating,
  source,
  link,
  sourceLogo,
}) {
  return (
    <p-card layout="column">
      <p-image-header
        imageuri={avatarUrl}
        alt={comment}
        style={{
          backgroundImage: `url(${headerImage})`,
          backgroundSize: "60%",
          backgroundRepeat: "repeat",
        }}
      />
      <p-card-content>
        {source !== "press" ? (
          <pw-row>
            <pw-col nm="7">
              <pw-heading size="small" color="green">
                {title}
              </pw-heading>
            </pw-col>
            <pw-col style={{ marginLeft: "auto" }} nm="5">
              <StarRating rating={rating} />
            </pw-col>
          </pw-row>
        ) : (
          ""
        )}

        <p-lead>
          {source !== "press" ? (
            <pw-overflow>
              <p>{comment}</p>
            </pw-overflow>
          ) : (
            <Blockquote>
              <p>{comment}</p>
            </Blockquote>
          )}
        </p-lead>
      </p-card-content>
      <p-card-action>
        {source !== "press" ? (
          <ReviewLink to={`/${id}`} asModal>
            <p-button outline>Read more</p-button>
          </ReviewLink>
        ) : (
          <pw-row
            style={{
              width: "100%",
              justifyContent: "space-between",
              alignItems: "flex-end",
              margin: "0 auto",
            }}
          >
            <pw-col nm="3">
              <pw-avatar
                size="36px"
                imageuri={sourceLogo}
                type="square"
              ></pw-avatar>
            </pw-col>
            <pw-col
              nm="2"
              style={{
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <ExternalLink href={link} target="_blank" rel="noreferrer">
                <LinkIcon />
              </ExternalLink>
            </pw-col>
          </pw-row>
        )}
      </p-card-action>
    </p-card>
  )
}

const ReviewLink = styled(Link)`
  display: block;
  width: 100%;
`

const ExternalLink = styled.a`
  display: block;
  width: 30px;
  height: 30px;
  margin-left: auto;

  svg {
    width: 100%;
    height: 100%;
  }
`
const StyledReviewCard = styled(ReviewCard)`
  p-card-content {
    /* margin-bottom: auto; */
  }
`

export default StyledReviewCard
