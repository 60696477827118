import React from "react"
import { StaticQuery, graphql } from "gatsby"

export default function reviewQuery({ limit = 44, children }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          allAirtable {
            nodes {
              recordId
              data {
                reviewer
                date
                title
                comment
                link
                slug
                rating
                source
                sourceLogo {
                  url
                }
                avatar {
                  url
                }
                backgroundUrl {
                  url
                }
              }
            }
            pageInfo {
              totalCount
            }
          }
        }
      `}
      render={function handleData({ allAirtable }) {
        const { nodes, pageInfo } = allAirtable
        return children({
          reviews: nodes.slice(0, limit),
          hasNextPage: pageInfo.totalCount > limit,
        })
      }}
    />
  )
}
